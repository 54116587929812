import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useMisStore = create((set) => ({
    mis: [],misOther:null,searchMis:[],searchMisOther:null,
    fetchMis: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'enquiry/view-mis/'+ supervisor + '/?' +payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchLastMis: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/view-last-mis/'+payload+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchSearchMis: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/search-mis/'+payload+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    fetchMisEnquiry: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.get(url + 'enquiry/view-mis-enquiry/'+id+'/?'+payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        return data
    },
    setMis: async (data) => {
        set({ mis: data.data,misOther:{count: Number(data.count), currentPage: Number(data.currentPage),ts:data.ts,s:data.s} });
    },
    setSearchMis: async (data) => {
        set({ searchMis: data.data,searchMisOther:{count: Number(data.count), currentPage: Number(data.currentPage)} });
    },
    createMis: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/create-mis/'+id+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    createFreshMis: async (payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/create-fresh-mis/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    uploadPolicy: async (id,payload) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/policy-upload/'+id+'/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    updateMis: async (payload,id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.put(url + 'enquiry/update-mis/'+id+'/',payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    deleteMis: async (id) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.delete(url + 'enquiry/delete-mis/'+id+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response        
    },
    markPaid:async(payload)=>{
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/mark-paid/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    markPaidRenewal:async(payload)=>{
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/mark-paid-renewal/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    },
    importMis:async(payload)=>{
        let token = await JSON.parse(localStorage.getItem('user'))
        const response = await axios.post(url + 'enquiry/import-mis/', payload, {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        return response
    }
}));