import React from 'react'
import { Icon } from '@iconify/react';
import { useColorStore } from './../../../Store/ColorStore';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { AdminPath } from './../AdminRoutes/AdminPath';

const NotificationList = (props) => {
    const { data } = props
    const { color } = useColorStore()
    const ntype={
        'CHANGE_OWNER':'CHANGE_OWNER',
        'NEW_NOTIFICATION':'NEW_NOTIFICATION',
        'EMAIL_NOTIFICATION':'EMAIL_NOTIFICATION',
        'REPLY_EMAIL_NOTIFICATION':'REPLY_EMAIL_NOTIFICATION',
        'ASSIGN_FIELD_EXECUTIVE':'ASSIGN_FIELD_EXECUTIVE',
        'ASSIGN_SOURCE_LEAD':'ASSIGN_SOURCE_LEAD',
        'TASK_ASSIGN':'TASK_ASSIGN',
        'FIELD_WORK_DONE':'FIELD_WORK_DONE',
        'MIS_ISSUED':'MIS_ISSUED',
        'ENQ_CALLBACK':'ENQ_CALLBACK',
        'FIELD_CALLBACK':'FIELD_CALLBACK',
        'MIS_CALLBACK':'MIS_CALLBACK',
        'RENEWAL_CALLBACK':'RENEWAL_CALLBACK',
    }
    return (
        <>
            {/* -----------------Assigned leads------------------------------------- */}
            {data.ntype === ntype.CHANGE_OWNER &&
                <Link to={AdminPath.ASSIGNEDLEADS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="icon-park-outline:google-ads" className='noti_ico' style={{ backgroundColor: color.blue2 }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', fontWeight: 450 }}>New Data Assigned</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {data.ntype === ntype.ENQ_CALLBACK &&
                <Link to={AdminPath.FOLLOWUPLEADS + "/call-back"} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="si:phone-callback-fill" className='noti_ico' style={{ backgroundColor: color.blue2 }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', fontWeight: 450 }}>Callback Notification</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------*** Field Notification ***--------------------------------------- */}
            {data.ntype === ntype.ASSIGN_FIELD_EXECUTIVE &&
                <Link to={AdminPath.FIELDLEADS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="tabler:square-letter-f" className='noti_ico' style={{ backgroundColor: color.blue2 }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Field Lead Assigned</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {data.ntype === ntype.FIELD_WORK_DONE &&
                <Link to={AdminPath.FIELDLEADS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="tabler:square-letter-f" className='noti_ico' style={{ backgroundColor: "#000" }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Field Lead Paid</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {data.ntype === ntype.FIELD_CALLBACK &&
                <Link to={AdminPath.FIELDLEADS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="si:phone-callback-fill" className='noti_ico' style={{ backgroundColor: "#000" }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Callback Notification</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------Source Lead Notification--------------------------------------- */}
            {data.ntype === ntype.ASSIGN_SOURCE_LEAD &&
                <Link to={AdminPath.SOURCELEADS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="material-symbols:mist" className='noti_ico' style={{ backgroundColor: color.yellow }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>New Source Lead</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------General Notification--------------------------------------- */}
            {data.ntype === ntype.TASK_ASSIGN &&
                <Link to={AdminPath.TASKS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="ic:twotone-task" className='noti_ico' style={{ backgroundColor: "#000" }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Task Notification</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------Email Notification--------------------------------------- */}
            {data.ntype === 'EMAIL_NOTIFICATION' &&
                <Link to={AdminPath.INBOX} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="iconoir:user" className='noti_ico' style={{ backgroundColor: color.blue }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>New Email Recieved.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------Email Notification--------------------------------------- */}
            {data.ntype === 'REPLY_EMAIL_NOTIFICATION' &&
                <Link to={AdminPath.INBOX} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="iconoir:user" className='noti_ico' style={{ backgroundColor: color.blue }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Email Reply Recieved.</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {/* -----------------------------------MIS Issued Notification--------------------------------------- */}
            {data.ntype === ntype.MIS_ISSUED &&
                <Link to={AdminPath.MIS} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="bx:rupee" className='noti_ico' style={{ backgroundColor: color.green }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Policy Issued</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {data.ntype === ntype.MIS_CALLBACK &&
                <Link to={AdminPath.RENEWDATA} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="si:phone-callback-fill" className='noti_ico' style={{ backgroundColor: color.green }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Callback Notification</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

            {data.ntype === ntype.RENEWAL_CALLBACK &&
                <Link to={AdminPath.RENEWAL} className="d-flex notification_wrapper">
                    <div>
                        <Icon icon="si:phone-callback-fill" className='noti_ico' style={{ backgroundColor: color.green }} />
                    </div>
                    <div style={{ marginLeft: '20px' }}>
                        <div className='mb-1' style={{ color: color.darkText, fontSize: '14px', letterSpacing: '0.2px', fontWeight: 450 }}>Callback Notification</div>
                        <div style={{ color: color.lightText, fontSize: '12px', marginBottom: '3px' }}>{data.message}</div>
                        <div className='d-flex' style={{ color: color.lightText, fontSize: '12px', alignItems: 'center' }}><Icon icon="octicon:clock-24" fontSize={12} />&nbsp; <span>{moment(data.timestamp).startOf('seconds').fromNow()}</span></div>
                    </div>
                </Link>
            }

        </>
    )
}

export default NotificationList