export const AdminPath = {
    DASHBOARD:'/',
    CATEGORY:'/category',
    SUBCATEGORY:'/sub-category',
    PRODUCT:'/product',
    PDFFILES:'/pdf-files',
    PRODUCTBROCHURE:'/product-brochure',
    QRSCANNER:'/qr-scanner',
    DYNAMICEMAIL:'/dynamic-email',
    IPADDRESS:'/ip-address',
    USERS:'/users',
    PROFILE:'/profile',
    EMAIL:'/email/*',
    INBOX:'/email/inbox',
    STARRED:'/email/starred',
    SENTMAIL:'/email/sent-mail',
    TRASH:'/email/trash',
    LABELS:'/email/labels/',
    READEMAIL:'/email/',
    // ---------------------Leads urls---------------------------
    CLAIMS:'/claims',
    TASKS:'/tasks',
    LEADS:'/leads',
    FIELDLEADS:'/field-leads',
    ALLLEADS:'/data',
    NEWLEADS:'/new-data',
    MYDATA:'/my-data',
    SEARCH:'/search',
    ASSIGNEDLEADS:'/assigned-data',
    FOLLOWUPLEADS:'/follow-up-data',
    LEADSSERVICES:'/service-list',
    SOURCELEADS:'/source-leads',
    ALLINVOICES:'/invoices',
    RECOMMENDATION:'/recommendation',
    MIS:'/mis',
    PR:'/pr',
    RENEWDATA:'/renew-data',
    RENEWAL:'/renewal',
    // ===========================User Permissions Urls==========================
    USERPERMISSION:'/user-permissions',
    // ===========================Setting Urls==========================
    SETTINGS:'/settings',
    // ===========================Website Urls==========================
    WEBENQUIRY:'/web-enquiry',
    NEWSLETTER:'/newsletter',
    REQDEMO:'/requested-demo',
    PAYMENTUSER:'/payment-user',
    COMPLAINT:'/complaint',
    COMPLAINTDATA:'/complaint-data',
    BLOGS:'/blogs',
    // ===========================Research Urls==========================
    PASTPERFORMANCE:'/past-performance',
    LOTSIZE:'/lotsize',
    RESEARCHPRODUCT:'/research-product',
    RESEARCHPUPDATE:'/research-update',
    RESEARCHCALL:'/send-recommendations',
    RESEARCHUPDATES:'/send-updates',
    SAR:'/support-resistance',
    COMPLIANCEMSG:'/compliance-message',
}