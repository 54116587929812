import React, { useEffect } from 'react'
import { useColorStore } from '../../../../../Store/ColorStore'
import { useState } from 'react'
import { useLeadDetailStore } from '../../../../../Store/LeadStore/LeadDetailStore'
import { useToasts } from 'react-toast-notifications'
import { userType } from '../../../../../Export'

const Profile = (props) => {
  const { addToast } = useToasts();
  let token = JSON.parse(localStorage.getItem('user'))
  const { users, isLoading, setIsLoading, leadUser, leadEnquiry, userId, permission, permission2 } = props
  const { color } = useColorStore()
  const { updateLeadUserDetails, fetchLeadDetails, updateLeadEnquiryDetails } = useLeadDetailStore()

  const [userPayload, setUserPayload] = useState({
    name: leadUser.name,
    contact: leadUser.contact,
    email: leadUser.email ? leadUser.email : '',
  })

  const [enquiryPayload, setEnquiryPayload] = useState({
    lead_source: leadEnquiry.lead_source,
    alternate_no: leadEnquiry.alternate_no,
    occupation: leadEnquiry.occupation,
    capital: leadEnquiry.capital,
    tsegment: leadEnquiry.tsegment,
    broker_detail: leadEnquiry.broker_detail,
    yearly_income: leadEnquiry.yearly_income,
    gst: leadEnquiry.gst,
    address: leadEnquiry.address,
    states: leadEnquiry.states,
    profession: leadEnquiry.profession,
    age: leadEnquiry.age,
  })

  useEffect(() => {
    setUserPayload({
      ...userPayload,
      name: leadUser.name,
      contact: leadUser.contact,
      email: leadUser.email ? leadUser.email : '',
    })
  }, [leadUser])

  useEffect(() => {
    setEnquiryPayload({
      ...enquiryPayload,
      lead_source: leadEnquiry.lead_source ? leadEnquiry.lead_source : "",
      alternate_no: leadEnquiry.alternate_no ? leadEnquiry.alternate_no : "",
      occupation: leadEnquiry.occupation ? leadEnquiry.occupation : "",
      capital: leadEnquiry.capital ? leadEnquiry.capital : "",
      tsegment: leadEnquiry.tsegment ? leadEnquiry.tsegment : "",
      broker_detail: leadEnquiry.broker_detail ? leadEnquiry.broker_detail : "",
      exp: leadEnquiry.exp ? leadEnquiry.exp : "",
      yearly_income: leadEnquiry.yearly_income ? leadEnquiry.yearly_income : "",
      gst: leadEnquiry.gst ? leadEnquiry.gst : "",
      address: leadEnquiry.address ? leadEnquiry.address : "",
      states: leadEnquiry.states ? leadEnquiry.states : "",
      profession: leadEnquiry.profession ? leadEnquiry.profession : "",
      age: leadEnquiry.age ? leadEnquiry.age : "",
    })
  }, [leadEnquiry])

  const [error2, setError2] = useState({
    name: '',
    contact: '',
    email: '',
  })

  const handleValidation = () => {
    let formIsValid = true;
    let errors = { ...error2 }
    if (userPayload.name.length === 0) {
      formIsValid = false;
      errors.name = 'Required...!'
    } else {
      errors.name = ''
    }
    if (userPayload.contact.length !== 10) {
      formIsValid = false;
      errors.contact = 'Please enter valid contact number...!'
    } else {
      errors.contact = ''
    }
    if (userPayload.email.length > 0) {
      if (!userPayload.email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
        formIsValid = false;
        errors.email = 'Please Enter valid Email ID...!'
      } else {
        errors.email = ''
      }
    } else {
      errors.email = ''
    }
    setError2(errors)
    return formIsValid
  }

  const handleUpdateLeadUser = () => {
    if (handleValidation()) {
      setIsLoading(true)
      updateLeadUserDetails(userId, userPayload)
        .then(res => {
          setIsLoading(false)
          fetchLeadDetails(userId)
          addToast('Success...', { appearance: 'success' });
        })
        .catch(err => {
          setIsLoading(false)
          console.log(err);
          addToast('Error...', { appearance: 'error' });
        })
    }
  }

  const handleUpdateLeadEnquiry = () => {
    setIsLoading(true)
    updateLeadEnquiryDetails(leadEnquiry.id, enquiryPayload)
      .then(res => {
        setIsLoading(false)
        fetchLeadDetails(userId)
        addToast('Success...', { appearance: 'success' });
      })
      .catch(err => {
        setIsLoading(false)
        console.log(err);
        addToast('Error...', { appearance: 'error' });
      })

  }

  return (
    <>
      <div class="menu-title ps-0" style={{ color: color.darkText, opacity: '1', fontSize: '13px' }}>Contact Details</div>
      <div className="contact_detail_wrapper">

        <div className="myrow justify_space_between mb-0">

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Name</div>
            <div className="">
              <input type="text" value={userPayload.name} onChange={(e) => setUserPayload({ ...userPayload, name: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} />
              {error2.name.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.name}</div>}
            </div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Email</div>
            <div className="">
              <input type="text" value={userPayload.email} readOnly={!permission} onChange={(e) => setUserPayload({ ...userPayload, email: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} />
              {error2.email.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.email}</div>}
            </div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Contact</div>
            <div className="">
              <input type="text" value={userPayload.contact} readOnly={!permission} onChange={(e) => setUserPayload({ ...userPayload, contact: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} />
              {error2.contact.length > 0 && <div className="text-danger" style={{ fontSize: '13px' }}>{error2.contact}</div>}
            </div>
          </div>
        </div>

        <div className="myrow justify_space_between align_center mb-0">
          <div className="detail_block" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} ></div>
         
            <div className="detail_block"><button onClick={handleUpdateLeadUser} className="primaryButton">Submit</button></div>
          
        </div>
      </div>

      <hr style={{ borderTop: '1px solid ' + color.lightText,margin:0 }} />

      {/* ********************************Other Details**************************** */}

      <div class="menu-title ps-0" style={{ color: color.darkText, opacity: '1', fontSize: '13px' }}>Other Details</div>
      <div className="contact_detail_wrapper">

        <div className="myrow justify_space_between mb-0">
          {token.user_type === userType.admin && <>
            <div className="detail_block">
              <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Lead Source</div>
              <select value={enquiryPayload.lead_source} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, lead_source: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                <option value="FB">Facebook</option>
                <option value="O">Organic</option>
                <option value="G">Google</option>
                <option value="B">Bulk</option>
                <option value="OT">Other</option>
              </select>
            </div>
          </>}


          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Alternate Number</div>
            <div className="">
              <input type="text" value={enquiryPayload.alternate_no} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, alternate_no: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} />
            </div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Product</div>
            <div className=""><input type="text" value={enquiryPayload.occupation} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, occupation: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} /></div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Sum</div>
            <div className=""><input type="text" value={enquiryPayload.yearly_income} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, yearly_income: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} /></div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Premium</div>
            <div className=""><input type="text" value={enquiryPayload.gst} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, gst: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} /></div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Profession</div>
            <div className=""><input type="text" value={enquiryPayload.profession} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, profession: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} /></div>
          </div>

          <div className="detail_block">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Age</div>
            <div className=""><input type="text" value={enquiryPayload.age} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, age: e.target.value })} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px' }} /></div>
          </div>

          <div className="detail_block w-100">
            <div className="" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} >Address</div>
            <div className="">
              <textarea value={enquiryPayload.address} onChange={(e) => setEnquiryPayload({ ...enquiryPayload, address: e.target.value })} className='localSearchInput pt-2' rows={3} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, borderRadius: '2px', height: 'auto' }}></textarea>
            </div>
          </div>

        </div>


        <div className="myrow justify_space_between align_center mb-0">
          <div className="detail_block" style={{ color: color.lightText, fontSize: '12px', fontWeight: 400, textTransform: 'capitalize' }} ></div>
          <div className="detail_block"><button className="primaryButton" onClick={handleUpdateLeadEnquiry}>Submit</button></div>
        </div>

      </div>
    </>
  )
}

export default Profile