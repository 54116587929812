import React, { useRef, useEffect, useState } from 'react'
import { useColorStore } from '../../Store/ColorStore';
import * as echarts from 'echarts';
import { Icon } from '@iconify/react';
import Ripples from 'react-ripples';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import moment from 'moment';
import { Months, numFormatter, userType } from './../../Export';
import { AdminPath } from './../Admin/AdminRoutes/AdminPath';
import { useFieldDashboardStore } from '../../Store/DashboardStore.js/FieldDashboardStore';


const OperationDashboard = () => {
    let token = JSON.parse(localStorage.getItem('user'))
    const navigate = useNavigate()
    const [today, setToday] = useState(moment().format('YYYY-MM-DD'))
    const [tommorow, setTommorow] = useState(moment().add(1, 'days').format('YYYY-MM-DD'))
    const [startdate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
    const { fetchOTotals, ototals } = useFieldDashboardStore()
    const { color } = useColorStore()

    const [fetcher, setFetcher] = useState({
        month: '', year: ''
    })
    useEffect(() => {
        fetchOTotals(fetcher.month, fetcher.year)
    }, [])

    // -------------------------------------Search Work-------------------------------------

    const [searchInput, setSearchInput] = useState("")

    const search = () => {
        navigate(AdminPath.SEARCH + '/' + searchInput)
    }

    return (
        <>
            {/* ------------------------------------------------- */}
            {token.user_type === userType.admin || token.user_type===userType.floor_manager ?
                <h6 style={{ color: color.darkText, marginBottom: '15px' }}>Operation Dashboard</h6>
                : <div className="myrow mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                </div>
            }
            {/* ------------------------------------------------- */}
            <div className="myrow justify_space_between">

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="hugeicons:renewable-energy-01" fontSize={32} color={color.blue} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Sourcing</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.sa)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.s}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="ic:sharp-currency-rupee" fontSize={32} color={color.green} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=I&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Issued</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.ia)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.i}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="material-symbols:pending-actions-rounded" fontSize={32} color={color.blue2} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=P&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Pending</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.pa)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.p}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="oui:index-close" fontSize={32} color={color.red} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=C&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Cancelled</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.ca)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.c}</div>
                        </Link>
                    </div>
                </div>
            </div>
            <div className="myrow" style={{ justifyContent: 'space-evenly' }}>
                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="oui:index-close" fontSize={32} color={color.yellow} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=CC&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Cancelled by Client</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.cca)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.cc}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="oui:index-close" fontSize={32} color={color.red} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=D&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Decline</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.da)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.d}</div>
                        </Link>
                    </div>
                </div>

                <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
                    <div className="d-flex align_center">
                        <div>
                            <div className='ico_bg'><Icon icon="oui:index-close" fontSize={32} color={"rgb(207, 102, 121)"} /></div>
                        </div>
                        <Link to={`${AdminPath.MIS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=CB&type=&type=&startDate=${startdate}&endDate=${endate}&search=`}>
                            <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Cheque Bounce</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Amount</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(ototals.cba)}</div>
                            <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>No. of Cases</div>
                            <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{ototals.cb}</div>
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default OperationDashboard