import React, { useRef, useEffect, useState } from 'react'
import { useColorStore } from '../../Store/ColorStore';
import * as echarts from 'echarts';
import { Icon } from '@iconify/react';
import Ripples from 'react-ripples';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import moment from 'moment';
import { numFormatter, userType } from './../../Export';
import { AdminPath } from './../Admin/AdminRoutes/AdminPath';
import { useFieldDashboardStore } from '../../Store/DashboardStore.js/FieldDashboardStore';


const FieldDashboard = () => {
  let token = JSON.parse(localStorage.getItem('user'))
  const navigate = useNavigate()
  const [today, setToday] = useState(moment().format('YYYY-MM-DD'))
  const [tommorow, setTommorow] = useState(moment().add(1, 'days').format('YYYY-MM-DD'))
  const [startdate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
  const [endate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))
  const { fetchTotals, fetchTotals2, totals2, totals } = useFieldDashboardStore()
  const { color } = useColorStore()

  useEffect(() => {
    fetchTotals()
    fetchTotals2()
  }, [])

  // -------------------------------------Search Work-------------------------------------

  const [searchInput, setSearchInput] = useState("")

  const search = () => {
    navigate(AdminPath.SEARCH + '/' + searchInput)
  }

  return (
    <>
      {/* ------------------------------------------------- */}
      {token.user_type === userType.admin || token.user_type===userType.floor_manager ?
        <h6 style={{ color: color.darkText, marginTop: '5px' }}>Field Dashboard</h6>
        : <div className="myrow mb-4">
          <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
            <div>
              <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
              <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
            </div>
            <div className='ms-2'>
              <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
              </Ripples>
            </div>
          </div>
        </div>
      }
      {/* ------------------------------------------------- */}
      <div className="myrow justify_space_between">

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="ic:round-phone-callback" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={AdminPath.FIELDLEADS}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{totals.total_callbacks ? numFormatter(totals.total_callbacks) : 0}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:callback" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.FIELDLEADS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=&endDate=&startDate2=${today}&endDate2=${today}&startDate3=&endDate3=&search=&assign_by=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Today Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.today_callbacks)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:call-transfer" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.FIELDLEADS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=&endDate=&startDate2=${tommorow}&endDate2=${tommorow}&startDate3=&endDate3=&search=&assign_by=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Tomorrow Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.tomorrow_callbacks)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:end-call" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.FIELDLEADS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=&endDate=&startDate2=1900-01-01&endDate2=${today}&startDate3=&endDate3=&search=&assign_by=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Pending Callbacks</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals.pending_callbacks)}</div>
            </Link>
          </div>
        </div>

      </div>
      {/* ------------------------------------------------- */}
      <div className="myrow justify_space_between">

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="ic:round-phone-callback" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={AdminPath.FIELDLEADS}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Total Visits</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{totals2.total_visits ? numFormatter(totals2.total_visits) : 0}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:callback" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.FIELDLEADS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=&endDate=&startDate3=${today}&endDate3=${today}&startDate2=&endDate2=&search=&assign_by=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Today Visits</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>For Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals2.today_visits)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:call-transfer" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.FIELDLEADS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=&endDate=&startDate3=${tommorow}&endDate3=${tommorow}&startDate2=&endDate2=&search=&assign_by=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Tomorrow Visits</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Now</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals2.tomorrow_visits)}</div>
            </Link>
          </div>
        </div>

        <div className="dash_top_block p-3" style={{ backgroundColor: color.secondaryBg }}>
          <div className="d-flex align_center">
            <div>
              <div className='ico_bg'><Icon icon="flat-color-icons:end-call" fontSize={32} color={color.blue} /></div>
            </div>
            <Link to={`${AdminPath.FIELDLEADS}?itemsPerPage=10&currentPage=1&owner=&filterServiceState=&status=&startDate=&endDate=&startDate3=1900-01-01&endDate3=${today}&startDate2=&endDate2=&search=&assign_by=`}>
              <div style={{ color: color.lightText, fontSize: '16px', marginBottom: '10px' }}>Pending Visits</div>
              <div style={{ color: color.blue, opacity: 0.8 }} className='menu-title p-0'>Till Today</div>
              <div style={{ color: color.darkText, fontWeight: 600, fontSize: '20px' }}>{numFormatter(totals2.pending_visits)}</div>
            </Link>
          </div>
        </div>

      </div>
    </>
  )
}

export default FieldDashboard