import { Icon } from '@iconify/react/dist/iconify.js'
import React from 'react'
import { useColorStore } from '../../../Store/ColorStore'
import moment from 'moment'

const PrList = (props) => {
    const { data, count } = props
    const { color } = useColorStore()
    return (
        <>
            <tr>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{count}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.system_issue_date ? data.system_issue_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.inward_date ? data.inward_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.quote ? data.quote : ''}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.prev_policy_number ? data.prev_policy_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.transaction_type ? data.transaction_type : '-'}</td>
                {/* <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.product_code ? data.product_code : '-'}</td> */}
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.product_name ? data.product_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.insured_name ? data.insured_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}><button className="infoButton" data-bs-toggle="modal" data-bs-target={"#lotsizeImportModal"+data.id} >View</button></td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_number ? data.policy_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_start_date ? data.policy_start_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_end_date ? data.policy_end_date : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement ? data.endorsement : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement_type ? data.endorsement_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.endorsement_reason ? data.endorsement_reason : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.policy_type ? data.policy_type : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.sum_assured ? data.sum_assured : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.net_premium ? data.net_premium : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.gross_premium ? data.gross_premium : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.payment_mode ? data.payment_mode : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.instrument_number ? data.instrument_number : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.bank_name ? data.bank_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{data.branch_name ? data.branch_name : '-'}</td>
                <td style={{ borderBottom: '1px solid ' + color.tableBorder, color: color.darkText }}>{moment(data.created_at).format('LLL')}</td>
                {/* <td style={{ borderBottom: '1px solid ' + color.tableBorder }}>
                    <ul class="list-inline mb-0">
                        <li class="list-inline-item">
                            <span type="button" class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryModal" + data.id} style={{ color: color.blue }}><Icon icon="akar-icons:edit" fontSize={18} /></span>
                        </li>
                        <li class="list-inline-item">
                            <span type={'button'} class="px-2" data-bs-toggle="modal" data-bs-target={"#categoryDeleteModal" + data.id} style={{ color: color.red }}><Icon icon="ep:delete" fontSize={17} /></span>
                        </li>
                    </ul>
                </td> */}
            </tr>
            {/* ----------------------Address Modal---------------------------- */}

            <div class="modal fade" id={"lotsizeImportModal" + data.id} tabindex="-1" aria-labelledby={"lotsizeImportModalLabel" + data.id} aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id={"lotsizeImportModalLabel" + data.id}>Address</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder, color: color.darkText }}>
                            {data.address || ''}
                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='mclosebtn' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default PrList