// main.js or your main script file
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDDC2DZhBX6G-1hARlsNbIx376zgYENEtU",
    authDomain: "moneybugs-d0dae.firebaseapp.com",
    projectId: "moneybugs-d0dae",
    storageBucket: "moneybugs-d0dae",
    messagingSenderId: "983574521112",
    appId: "1:983574521112:web:d7971023a57b65d16dea6d"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const YOUR_VAPID_KEY = "BKXt8dOQktKBCGowWyyAmesTYcO1o6B66NyzuG_wJ13ru_Urb65RDOtsnW_Hhhe0bu8nL8_0etA7ht4sBJclkKk";

export const requestFirebaseNotificationPermission = async () => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
        const currentToken = await getToken(messaging, { vapidKey: YOUR_VAPID_KEY });
        if (currentToken) {
            return currentToken
            // Send the token to your Django backend
        } else {
            console.log("No registration token available.");
            return null
        }
    }else {
        console.log("Notification permission denied.");
      }
    } catch (error) {
        console.error("Error getting token: ", error);
    }
};

export const onForegroundMessage = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log("Message received in foreground: ", payload);
            resolve(payload);
        });
    });

