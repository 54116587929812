import React, { useEffect, useState } from 'react'
import Sidebar from '../Sidebar/Sidebar'
import './Style.css'
import Header from '../Header/Header'
import { useColorStore } from '../../../Store/ColorStore'
import { AdminRoutes } from '../AdminRoutes/AdminRoutes'
import { useUserPermissionStore } from './../../../Store/UserPermissionStore/UserPermissionStore';
import { socket } from '../../../Export'
import { useSocketStore } from '../../../Store/SocketStore/SocketStore'
import { useToasts } from 'react-toast-notifications'
import { requestFirebaseNotificationPermission, onForegroundMessage } from '../../../firebase';

const AdminDashboard = (props) => {
  const { addToast } = useToasts()
  const { user } = props
  const { color } = useColorStore();
  const { fetchUserPermissions, userPermissions, subscribeToken } = useUserPermissionStore()
  // ***********************************Notification Work***********************************
  useEffect(() => {
    // Request permission and get the FCM token
    requestFirebaseNotificationPermission()
      .then(res => {
        console.log(res);
        
        if (res) {
          subscribeToken({ fcm_token: res })
            .then(r => {
              console.log(r)
            })
            .catch(err => {
              addToast("Notification Token not saved.", {
                appearance: 'error',
                autoDismiss: true,
              })
            })
        } else {
          addToast("Notification Token not generated, please allow permission.", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      })
    // Handle foreground notifications
  }, []);
  onForegroundMessage().then((payload) => {
    console.log("Foreground notification: ", payload);
    addToast(payload.notification.title + '\n' + payload.notification.body, {
      appearance: 'info',
      autoDismiss: false,
    })
  });
  // ***********************************Notification Work***********************************

  useEffect(() => {
    fetchUserPermissions()
  }, [])

  // --------------------Socket Work------------------------------------------
  const { connectSocket, state, newMarketUpdate } = useSocketStore()
  const check = () => {
    if (state) {
      const { ws } = state;
      if (!ws || ws.readyState == WebSocket.CLOSED); //check if websocket instance is closed, if so call `connect` function.
    }
  };

  useEffect(() => {
    if (!state || state.readyState == WebSocket.CLOSED) {
      connectSocket();
    }
  }, [state])

  // useEffect(() => {
  //   if (newMarketUpdate) {
  //     addToast(newMarketUpdate, {
  //       appearance: 'info',
  //       autoDismiss: false,
  //     })
  //   }
  // }, [newMarketUpdate])

  const handleConnectSocket = () => connectSocket()

  return (
    <>
      {!state || state.readyState == WebSocket.CLOSED ?
        <div class="position-fixed top-0 end-0 pt-3" style={{ zIndex: "999999" }} >
          <div id="liveToast" class="toast show w-100" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header" style={{ backgroundColor: color.yellow, color: color.darkText }}>
              <strong class="me-auto">Warning</strong>
              <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body" style={{ backgroundColor: color.tableBorder, color: color.darkText }}>
              WebSocket Not Connected, Please click on connect button to connect now.

              <div class="mt-2 pt-2">
                <button type="button" onClick={handleConnectSocket} class="btn btn-primary btn-sm">Connect Now</button>
              </div>
            </div>

          </div>
        </div>
        : null}

      <div className="layoutWrapper">
        <div className="desktop">
          <Sidebar user={user} />
        </div>
        <div className='main-body' style={{ backgroundColor: color.primaryBg }}>
          <Header user={user} />
          <div className="content-wrapper">
            <AdminRoutes />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboard