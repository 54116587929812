import React, { useState, useEffect } from 'react'
import Ripples from 'react-ripples';
import { useColorStore } from '../../../Store/ColorStore';
import { Icon } from '@iconify/react';
import PrList from './PrList';
import { useToasts } from 'react-toast-notifications';
import Loading from '../../../Loading';
import * as XLSX from "xlsx";
import { useLotsizeStore } from '../../../Store/LotsizeStore/LotsizeStore';
import { CSVLink } from 'react-csv';
import ReactPaginate from 'react-paginate';
import { usePrStore } from '../../../Store/PrStore/PrStore';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router-dom';
import { numFormatter, productOptions } from '../../../Export';

const Pr = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const { addToast } = useToasts();
    const { pr, fetchPr, createPr, setPr, prOther } = usePrStore()
    const { color } = useColorStore()
    let token = JSON.parse(localStorage.getItem('user'))

console.log(prOther);

    const [isLoading, setIsLoading] = useState(false)
    const [payload, setPayload] = useState({
        stock_name: '',
        pr: '',
        exp: '',
    })
    const [reload, setReload] = useState(false)
    function search() {
        setReload(!reload)
    }
    const [error, setError] = useState(false)
    const [error2, setError2] = useState(false)

    const [file, setFile] = useState()
    const handleImport = () => {
        if (!file) {
            setError2(true)
        } else {
            setError2(false)
            setIsLoading(true)
            let data = new FormData()
            data.append('f', file)
            createPr(data)
                .then(res => {
                    fetch()
                    setFile()
                    setIsLoading(false)
                    let d = document.getElementById('mclosebtn')
                    d.click()
                    addToast('Success...', { appearance: 'success' });
                }).catch(err => {
                    setIsLoading(false)
                    if (err.response && err.response.data) {
                        addToast(err.response.data, { appearance: 'error' });
                    }
                    else {
                        addToast('Error...', { appearance: 'error' });
                    }
                })
        }
    }

    const [searchInput, setSearchInput] = useState("")

    let alist = [{
        system_issue_date: '',
        inward_date: '',
        quote: '',
        prev_policy_number: '',
        transaction_type: '',
        product_code: '',
        product_name: '',
        insured_name: '',
        address: '',
        policy_number: '',
        policy_start_date: '',
        policy_end_date: '',
        endorsement: '',
        endorsement_type: '',
        endorsement_reason: '',
        policy_type: '',
        sum_assured: '',
        net_premium: '',
        gross_premium: '',
        payment_mode: '',
        instrument_number: '',
        bank_name: '',
        branch_name: '',
    }]


    // ----------------------------------------------------------------------------Filteration work-------------------------------------------------------------------------------------------

    const [filterMode, setFilterMode] = useState(false)
    const [changeNotify, setChangeNotify] = useState(false)
    const [filterToggle, setFilterToggle] = useState(true)
    const [owner, setOwner] = useState('')
    const [filterServiceState, setFilterServiceState] = useState([])
    const [status, setStatus] = useState([])
    const [dateRange, setDateRange] = useState({
        startDate: '',
        endDate: '',
    })

    // -------Options-----------
    const [options, setOptions] = useState([])

    const customStyles = {
        control: (base, state) => ({
            ...base,
            background: color.inputBackground,
            // match with the menu
            borderRadius: 4,
            // Overwrittes the different states of border
            borderColor: color.borderColor,
            fontSize: 14,
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: color.borderColor
            }
        }),
        menu: base => ({
            ...base,
            background: color.inputBackground,
            color: color.darkText,
            fontSize: 14,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            // const color = chroma(data.color);

            return {
                ...styles,
                backgroundColor: isFocused ? color.blue : null,
                color: isFocused ? "#fff" : null,
                // color: "#333333"
            };
        }
    };

    const handleRemoveOwner = () => {
        setOwner('');
    }
    const handleRemoveFilterServiceState = (index) => {
        setFilterServiceState([
            ...filterServiceState.slice(0, index),
            ...filterServiceState.slice(index + 1, filterServiceState.length)
        ]);
    }
    const handleRemoveFilterStatus = (index) => {
        setStatus('');
    }

    useEffect(() => {
        if (location.search.length > 0 && options.length > 0) {
            const filterParams = new URLSearchParams(location.search);
            const nstatus = filterParams.get('status');
            const nowner = filterParams.get('owner');
            const nstartDate = filterParams.get('startDate');
            const nendDate = filterParams.get('endDate');
            const nsearch = filterParams.get('search');
            setStatus(nstatus)
            setOwner(nowner)
            setDateRange({ ...dateRange, startDate: nstartDate, endDate: nendDate })
            setSearchInput(nsearch)
            setChangeNotify(true)
        }
        else {
            if (location.search.length === 0) {
                clearFilter()
            }
        }
    }, [location.search, options])

    const applyFilter = () => {
        setChangeNotify(false)
        if (owner.length > 0 || filterServiceState.length > 0 || status.length > 0 || dateRange.startDate.length > 0 && dateRange.endDate.length > 0) {
            setFilterMode(true)
            setFilterToggle(!filterToggle)
            let u = `?itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&owner=${owner}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
            navigate(`${u}`);
        } else {
            clearFilter()
        }
    }

    const clearFilter = () => {
        setOwner('')
        setFilterServiceState([])
        setStatus([])
        setDateRange({ ...dateRange, startDate: '', endDate: '' })
        navigate('')
        setFilterMode(false)
        setReload(!reload)
    }

    useEffect(() => {
        if (changeNotify) {
            applyFilter()
        }
    }, [changeNotify])

    // --------------------Pagination Works------------------------------

    // Pagination Work Start

    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1)
    const [itemOffset, setItemOffset] = useState(0);
    const [itemEndSet, setItemEndSet] = useState(0)
    const [itemLength, setitemLength] = useState(0)

    useEffect(() => {
        if (prOther) {
            const endOffset = itemOffset + itemsPerPage;
            setPageCount(prOther.count / itemsPerPage);
            setItemEndSet(endOffset)
            setitemLength(prOther.count)
        }
    }, [prOther]);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected + 1)
        const newOffset = (event.selected * itemsPerPage) % prOther.count;
        setItemOffset(newOffset);
    };

    // **********************************************************************************************

    const fetch = () => {
        let u = `itemsPerPage=${itemsPerPage}&currentPage=${currentPage}&productState=${owner}&startDate=${dateRange.startDate}&endDate=${dateRange.endDate}&search=${searchInput}`
        setIsLoading(true)
        fetchPr(u)
            .then(res => {
                setIsLoading(false)
                setPr(res)
            })
            .catch(err => {
                setIsLoading(false)
                console.log(err);
            })
    }
    useEffect(() => {
        fetch()
    }, [reload, itemsPerPage, currentPage, filterToggle])

    return (
        <>
            <div className="category_body" style={{ backgroundColor: color.secondaryBg }}>
                <div className="myrow justify_space_between mb-4">
                    <div className="ico-search d-flex align_center" style={{ position: 'relative' }}>
                        <div>
                            <input type="text" placeholder='Search...' value={searchInput} onChange={(e) => setSearchInput(e.target.value)} className='localSearchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder }} />
                            <span style={{ color: color.darkText }}><Icon icon="ion:search-outline" style={{ color: color.darkText }} fontSize={16} /></span>
                        </div>
                        <div className='ms-2'>
                            <Ripples color='rgba(255,255,255,0.2)' className='dis_block mb-0' during={650}>
                                <button onClick={search} className="primaryButton "><Icon icon="ion:search-outline" fontSize={18} /></button>
                            </Ripples>
                        </div>
                    </div>
                    <div>
                        <Ripples color='rgba(255,255,255,0.2)' className='mx-1' during={1000}>
                            <button className="successButton" data-bs-toggle="modal" data-bs-target="#lotsizeImportModal" >Import</button>
                        </Ripples>
                        <Ripples color='rgba(255,255,255,0.2)' className='mx-1' during={1000}>
                            <CSVLink data={pr} filename='pr.csv'><button className="warningButton left_border_radius"><Icon icon="ph:export-fill" fontSize={16} /> Export</button></CSVLink>
                        </Ripples>
                        <Ripples color='rgba(255,255,255,0.2)' className='' during={800}>
                            <button data-toggle="tooltip" data-placement="top" title="Filter" className="infoButton" data-bs-toggle="offcanvas" data-bs-target="#LeadsFilterCanvas" aria-controls="LeadsFilterCanvas" ><Icon icon="mdi:filter" fontSize={15} /> Filter</button>
                        </Ripples>
                    </div>
                </div>

                {filterMode &&
                    <div className='filter_values_wrapper'>
                        <div className="filter_values_title" style={{ color: color.darkText }}>Filter by &nbsp;:<span onClick={clearFilter} className="clear_filter_link">Clear filter</span></div>

                        {owner.length > 0 ?
                            <div className="filter_values">{owner} <Icon icon="fa6-solid:x" onClick={() => { handleRemoveOwner(); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} /></div>
                            : null
                        }

                        {dateRange.startDate.length > 0 && dateRange.endDate.length > 0 &&
                            <div className="filter_values">
                                Date Range - {moment(dateRange.startDate).format('ll')} - {moment(dateRange.endDate).format('ll')} <Icon icon="fa6-solid:x" onClick={() => { setDateRange({ ...dateRange, startDate: '', endDate: '' }); setChangeNotify(true) }} type="button" fontSize={16} style={{ paddingLeft: '5px' }} />
                            </div>
                        }

                    </div>
                }
                {/* ------------------------------Table Work-------------------------------------- */}

                <div class="table-responsive">
                    <div className="myrow justify_space_between">
                        <div style={{ color: color.darkText, fontSize: 12, marginBottom: '10px', marginRight: '10px' }}>
                            Show Entries - <select value={itemsPerPage} onChange={(e) => setItemsPerPage(Number(e.target.value))} className='searchInput' style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.tableBorder, width: '75px', padding: '5px', textAlign: 'center', borderRadius: '2px' }}>
                                <option value={10}>10</option>
                                <option value={1}>1</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                                <option value={100}>100</option>
                            </select>
                            &nbsp; Out of - {prOther ? prOther.count : 0}
                        </div>
                        <div className='border p-2' style={{ color: color.darkText, fontSize: 20 }}>
                            Total Bussiness - <span style={{ color: color.green }}>₹{prOther ? numFormatter(prOther.tr) : 0}</span>
                        </div>
                    </div>
                    <table class="table table-nowrap align-middle mb-0 table-bordered table-striped">
                        <thead style={{ backgroundColor: color.tableHeader }}>
                            <tr style={{ color: color.darkText }}>
                                <th scope="col" style={{ width: '50px', color: color.darkText }}>
                                    S.No.
                                </th>
                                <th style={{ color: color.darkText }} scope="col">System Issue Date</th>
                                <th style={{ color: color.darkText }} scope="col">Inward Date</th>
                                <th style={{ color: color.darkText }} scope="col">Quote No</th>
                                <th style={{ color: color.darkText }} scope="col">Previous Policy number</th>
                                <th style={{ color: color.darkText }} scope="col">Transaction Type</th>
                                {/* <th style={{ color: color.darkText }} scope="col">Product Code</th> */}
                                <th style={{ color: color.darkText }} scope="col">Product Name</th>
                                <th style={{ color: color.darkText }} scope="col">Insured Name</th>
                                <th style={{ color: color.darkText }} scope="col">Address</th>
                                <th style={{ color: color.darkText }} scope="col">Policy No</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Start Date</th>
                                <th style={{ color: color.darkText }} scope="col">Policy End Date</th>
                                <th style={{ color: color.darkText }} scope="col">Endorsement No</th>
                                <th style={{ color: color.darkText }} scope="col">Endorsement Type</th>
                                <th style={{ color: color.darkText }} scope="col">Endorsement Reason</th>
                                <th style={{ color: color.darkText }} scope="col">Policy Type</th>
                                <th style={{ color: color.darkText }} scope="col">Total Sum Assured</th>
                                <th style={{ color: color.darkText }} scope="col">Net Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Gross Premium</th>
                                <th style={{ color: color.darkText }} scope="col">Mode of Payment</th>
                                <th style={{ color: color.darkText }} scope="col">Instrument No</th>
                                <th style={{ color: color.darkText }} scope="col">Bank Name</th>
                                <th style={{ color: color.darkText }} scope="col">Branch Name</th>
                                <th style={{ color: color.darkText }} scope="col">Created At</th>

                                {/* <th scope="col" style={{ width: '200px', color: color.darkText }}>Action</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {pr.length > 0 ?
                                pr.map((c, count) => {
                                    return <PrList data={c} key={c.id} count={itemOffset + count + 1} setIsLoading={setIsLoading} isLoading={isLoading} />
                                })
                                :
                                <div style={{ color: color.darkText, fontSize: 14, textAlign: 'center' }}>No Record found....!</div>}
                        </tbody>
                    </table>

                    {pr.length > 0 &&
                        <div style={{ color: color.lightText, fontSize: '13px', marginTop: '5px' }}>Showing items from {itemOffset + 1} to {itemEndSet}.</div>
                    }
                    <ReactPaginate
                        nextLabel=">"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        marginPagesDisplayed={2}
                        pageCount={pageCount}
                        previousLabel="<"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="pagination-active"
                        renderOnZeroPageCount={null}
                    />
                </div>
            </div>
            {isLoading &&
                <Loading />}
            {/* ----------------------Add Modal---------------------------- */}
            {/* ------------------------------Filter modal----------------------------------- */}

            <div className="offcanvas offcanvas-end" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }} tabIndex="-1" id="LeadsFilterCanvas" aria-labelledby="offcanvasRightLabel">
                <div className="modal-content">
                    <div className="modal-header px-3 pt-3" style={{ borderBottomColor: color.tableBorder }}>
                        <div className="modal_title" style={{ color: color.darkText }} id="offcanvasRightLabel">Filter</div>
                        <span type="button" id='canvaCloseId' className='modal_close' data-bs-dismiss="offcanvas" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                    </div>
                </div>
                <hr style={{ borderTop: "0.5px solid " + color.tableBorder, opacity: '1' }} />
                <div className="offcanvas-body">

                    <div className="mb-3">
                        <label className='modal_label' style={{ color: color.darkText }}>Product</label>
                        <select value={owner} onChange={(e) => setOwner(e.target.value)} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input'>
                            <option value="">-----select product-----</option>
                            {productOptions.map((m, c) => {
                                return <option key={c} value={m.value}>{m.label}</option>
                            })}
                        </select>
                    </div>

                    <label className='modal_label' style={{ color: color.lightText }}>System Issue Date Range</label>
                    <div className="mb-4 mt-1">
                        <label className='modal_label' style={{ color: color.darkText }}>Start Date</label>
                        <input type="date" value={dateRange.startDate} onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="mb-4">
                        <label className='modal_label' style={{ color: color.darkText }}>End Date</label>
                        <input type="date" value={dateRange.endDate} onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                    </div>

                    <div className="pt-4">
                        <button className="primaryButton me-3" id='filterBtn' onClick={applyFilter}>Apply filter</button>
                        <button className="secondaryButton" onClick={clearFilter}>Clear filter</button>

                    </div>
                </div>
            </div>

            {/* ----------------------Import Modal---------------------------- */}

            <div class="modal fade" id="lotsizeImportModal" tabindex="-1" aria-labelledby="lotsizeImportModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <div class="modal-content" style={{ backgroundColor: color.secondaryBg, border: '1px solid ' + color.tableBorder }}>
                        <div class="modal-header" style={{ borderBottomColor: color.tableBorder }}>
                            <div class="modal_title" style={{ color: color.darkText }} id="lotsizeImportModalLabel">Import PR</div>
                            <span type="button" className='modal_close' data-bs-dismiss="modal" aria-label="Close"><Icon icon="ph:x-bold" color={color.darkText} /></span>
                        </div>
                        <div class="modal-body" style={{ borderBottomColor: color.tableBorder }}>

                            {error2 && <div className='text-danger' style={{ fontSize: '12px', marginTop: '-2px', marginBottom: '5px' }}>Please Select CSV File...!</div>}

                            <div className='mb-4'>
                                <label className='modal_label' style={{ color: color.darkText }}>Attach File (.csv,.xlsx) <span className="text-danger">*</span></label>
                                <input type='file' onChange={(e) => setFile(e.target.files[0])} style={{ color: color.darkText, backgroundColor: color.inputBackground, border: '1px solid ' + color.borderColor }} className='modal_input' />
                            </div>
                            {<CSVLink data={alist} filename={"sample.csv"}><div className='text-start'> <span style={{ fontSize: '14px', color: color.blue2 }}>Click here to Download CSV Format... </span></div></CSVLink>}
                        </div>
                        <div class="modal-footer" style={{ borderTopColor: color.tableBorder }}>
                            <button type="button" id='mclosebtn' style={{ color: color.darkText }} class="lightButton" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="primaryButton" disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }} onClick={handleImport}>{isLoading ?
                                <>
                                    <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> &nbsp;
                                    Please wait...
                                </>

                                : "Import"}</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Pr