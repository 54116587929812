import React, { useState, useEffect } from 'react'
import Login from './Login/Login'
import { url, userType } from './Export'
import axios from 'axios'
import AdminDashboard from './Components/Admin/AdminDashboard/AdminDashboard';
import { useColorStore } from './Store/ColorStore';
import './App.css'

const App = () => {

  const { fetchColor } = useColorStore()
  const [load, setLoad] = useState(true)
  const [user, setUser] = useState(undefined)

  let token = JSON.parse(localStorage.getItem('user'))

  useEffect(() => {
    if (token === null) {
      setLoad(false)
    } else {

      axios.get(url + 'auth/auth-user/', {
        headers: {
          'Authorization': 'Token ' + token.token
        }
      })
        .then(res => {
          setUser(res.data)
          setLoad(false)
        })
        .catch(err => {
          localStorage.removeItem("user")
          setLoad(false)
        })
    }

  }, [])

  useEffect(() => {
    fetchColor()
  }, [])


  if (!load) {

    return (
      <>
        {user ?
          <div className="">
            {/* {user.user_type === userType.admin || user.user_type === userType.floor_manager ? */}
              <AdminDashboard user={user} />
          </div>

          : <><Login /></>}
      </>
    )

  } else {
    return <>Loading...</>
  }
}

export default App