import { create } from 'zustand';
import axios from 'axios';
import { url } from './../../Export';

export const useFieldDashboardStore = create((set) => ({
    totals2:{'total_visits':0,'today_visits':0,'tomorrow_visits':0,'pending_visits':0},totals:{'total_callbacks':0,'today_callbacks':0,'tomorrow_callbacks':0,'pending_callbacks':0},
    rtotals:{ 'total_renewal':0,'paid':0,'unpaid':0,'lost':0,'tra':0,'tua':0,'tpa':0,'tla':0,'total_callbacks':0,'today_callbacks':0,'tomorrow_callbacks':0,'pending_callbacks':0},
    ototals:{ 's':0,'sa':0,'p':0,'pa':0,'i':0,'ia':0,'cc':0,'cca':0,'d':0,'da':0,'cb':0,'cba':0,'c':0,'ca':0},
    rtotals2:{ 'total_renewal':0,'paid':0,'unpaid':0,'lost':0,'tra':0,'tua':0,'tpa':0,'tla':0,'total_callbacks':0,'today_callbacks':0,'tomorrow_callbacks':0,'pending_callbacks':0},
    fetchTotals: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'dashboard/field-dashboard-totals/'+supervisor+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ totals:data});
    },
    fetchTotals2: async () => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'dashboard/field-dashboard-totals2/'+supervisor+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ totals2:data});
    },    
    fetchRTotals: async (m,y) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'dashboard/renewal-dashboard-totals/'+supervisor+'/'+m+'/'+y+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ rtotals:data});
    },
    fetchRenewalTotals: async (s,e) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'dashboard/renewal-totals/'+supervisor+'/'+s+'/'+e+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ rtotals2:data});
    },
    setRTotalNull:async()=>{
        set({ rtotals:{ 'total_renewal':0,'paid':0,'unpaid':0,'lost':0,'tra':0,'tua':0,'tpa':0,'tla':0}});
    },
    setRTotalNull2:async()=>{
        set({ rtotals2:{ 'total_renewal':0,'paid':0,'unpaid':0,'lost':0,'tra':0,'tua':0,'tpa':0,'tla':0}});
    },
    fetchOTotals: async (m,y) => {
        let token = await JSON.parse(localStorage.getItem('user'))
        let supervisor = JSON.parse(localStorage.getItem('supervisor') || false)
        const response = await axios.get(url + 'dashboard/operation-dashboard-totals/'+supervisor+'/', {
            headers: {
                'Authorization': 'Token ' + token.token,
            }
        });
        const data = await response.data;
        set({ ototals:data});
    },
    
}));